<template>
  <div class="anti-fork">
    <el-tabs>
      <el-tab-pane label="F02">
        <div class="my-container" :style="myStyle">
          <f02></f02>
        </div>
      </el-tab-pane>
      <el-tab-pane label="F03">
        <div class="my-container" :style="myStyle">
          <f03></f03>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import F02 from '@/views/Wechat/F02'
import F03 from '@/views/Wechat/F03'

export default {
  name: 'AntiFork',
  data () {
    return {
      myStyle: {
        overflowY: scroll
      }
    }
  },
  components: {
    F02,
    F03
  },
  mounted () {
    const myHeight = document.body.clientHeight - 70
    this.$set(this.myStyle, 'overflowY', 'scroll')
    this.$set(this.myStyle, 'height', myHeight + 'px')
  }
}
</script>

<style scoped lang="scss">
.anti-fork {
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  margin: -1rem;
  padding: 1rem;
}
.my-container {
  magin: 0 !important;
  width: 100%;
}
</style>
