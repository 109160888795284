<template>
  <div class="f02">
    <img ref="myIFrame" :src="url" v-if="status === 1" style="width: 100%;">
    <el-alert title="没有该报表查看权限" type="error" effect="dark" v-if="status === 2"></el-alert>
  </div>
</template>

<script>
import utility from '@/common/utility'
import auth from '@/common/auth'
import config from '@/config'
import reportService from '@/services/reportService'
import tenderService from '@/services/tenderService'

export default {
  name: 'F02',
  data () {
    return {
      periodGuid: this.$route.query.key,
      tenderGuid: '',
      reportGuid: '',
      tenders: [],
      status: 0
    }
  },
  computed: {
    url () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      return `${config.reportHost}/report?format=png&inline=true&period=${this.periodGuid}&report=${this.reportGuid}&timestamp=${timestamp}&parameter=${token}&pageindex=0&pagesize=0`
    }
  },
  methods: {
    getReportGuid (periodGuid, reportName) {
      reportService.getReportGuidByPeriod(periodGuid, reportName)
        .then(res => {
          if (res.data.code === 1) {
            this.reportGuid = res.data.data[0].split(',')[0]
            this.tenderGuid = res.data.data[0].split(',')[1]

            if (this.tenders.findIndex(tender => tender.tenderGuid === this.tenderGuid) !== -1) {
              this.status = 1
            } else {
              this.status = 2
            }
          }
        })
    },
    getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
            this.getReportGuid(this.periodGuid, 'f02')
          }
        })
        .catch(err => {
          console.log(err.request.response)
        })
    }
  },
  mounted () {
    this.getTenders()
  }
}
</script>

<style scoped lang="scss">
.f02 {
  width: 100%;
  height: 100%;
}
</style>
